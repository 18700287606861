<template>
  <div class="selector">
  <el-popover
    placement="bottom-start"
    width="228"
    @show="getList"
    >
    <div style="max-height: 300px; overflow: scroll;">
      <el-tree :size="size" :data="optionList" current-node-key="label" :props="defaultProps" highlight-current @node-click="handleNodeClick"></el-tree>
    </div>
    <el-input :size="size" slot="reference" ref="input" :value="defaultValue" clearable @clear="handleClear" placeholder="请选择备件类型" />
  </el-popover>
  </div>
</template>
<script>
import { getParentType, getAllType } from '@/api/spareParts';

export default {
  name: 'SPARETYPES_SELECTOR',
  props: {
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    id: {
      type: String,
      default: '',
    },
    isParent: {
      type: Boolean,
      default: false,
    },
    isQuery: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      optionList: [],
      defaultValue: this.name,
      params: {
        id: this.id,
      },
      defaultProps: {
        children: 'subset',
        label: 'name',
      },
      api: this.isParent ? getParentType : getAllType,
    };
  },
  watch: {
    name: function (val) {
      if (val !== this.defaultValue) {
        this.defaultValue = val;
      }
    },
    id: function (val) {
      this.params.id = val;
    },
    isParent: function (val) {
      this.api = val ? getParentType : getAllType;
    },
  },
  methods: {
    getList () {
      this.optionList = [];

      this.api(this.params)
        .then((res) => {
          if (!this.isParent && this.isQuery) {
            this.optionList.push({
              id: '',
              name: '全部',
              subset: res?.body || [],
            });
          } else {
            this.optionList = res?.body || [];
          }
        });
    },
    handleNodeClick (v) {
      if (v) {
        this.$emit('input', v.id);
        this.$emit('update:name', v.name);
        this.$emit('change', v);
      }
    },
    handleClear () {
      const v = {
        id: '',
        name: !this.isParent && this.isQuery ? '全部' : '',
      };

      this.$emit('input', v.id);
      this.$emit('update:name', v.name);
      this.$emit('change', v);
    },
  },
};
</script>
