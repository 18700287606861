import server from '../request';

// 备件列表
export const searchSpareParts = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/list', {...param,
    orderBy: [
      {
        'orderByProperty': 'createTime',
        'orderByType': 'DESC',
      },
    ]});
};

// 备件类型列表
export const getTypeList = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/type/list', param);
};

// 医疗分类列表
export const getMedicalTree = () => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/base/medicalTree');
};

// 新增备件
export const addSpareDevice = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/add', param);
};

// 编辑备件
export const updateSpareDevice = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/update', param);
};

// 备件详情
export const getSpareDeviceDetail = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/detail', param);
};

// 新增备件类型
export const addSparePartType = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/type/add', param);
};

// 编辑备件类型
export const updateSparePartType = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/type/update', param);
};

// 动态信息
export const log = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/log', param);
};

// 获取库存总计
export const getStockCount = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/inventory/getCount/bySparePartId', param);
};

// 获取库存列表
export const getStockList = (param) => {
  return server.post('/xiaozi-xmb/wireless/saas/inventory/page/bySparePartId', param);
};

// 获取所有备件类型
export const getAllType = () => {
  return server.post('/xiaozi-xmb/wireless/saas/sparePart/type/all/list');
};

// 获取父级备件类型
export const getParentType = (param) => {
  return server.post(`/xiaozi-xmb/wireless/saas/sparePart/type/tree/list?id=${param.id}`, {}, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  });
};

// 使用记录计数
export const getCount = (data) => server.post('/xiaozi-xmb/wireless/saas/sparePart/workOrder/count', data);

// 使用记录列表
export const getCountList = (data) => server.post('/xiaozi-xmb/wireless/saas/sparePart/workOrder/page', data);
