<template>
  <div>
    <query-form
      class="content_block"
      label-width="100px"
      @search="handleSearch"
      :span="8"
      @reset="handleReset"
      :defaultCollapsed="false"
    >
      <el-form-item label="仓库：" prop="warehouseId">
        <el-row type="flex">
          <el-select v-model="form.warehouseId">
            <el-option :value="undefined" label="全部">全部</el-option>
            <el-option
              v-for="item in warehouseSearchList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <i
            v-auth="'sparePart:stock:manager'"
            class="el-icon-setting"
            title="管理仓库"
            @click="$router.push({ name: 'SPAREPART_STOCK_MANAGER' })"
          ></i>
        </el-row>
      </el-form-item>
      <el-form-item label="备件名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入备件名称"></el-input>
      </el-form-item>
      <el-form-item label="备件编码：" prop="code">
        <el-input v-model="form.code" placeholder="请输入备件编码"></el-input>
      </el-form-item>
      <el-form-item label="备件类型：" prop="typeId">
        <SpareTypesSelectors :name="form.typeName" :isQuery="true" style="flex: 1" @change="typeNameChange" />
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="customerList"
      class="content_block"
      ref="stockTableRef"
      :checkbox-config="{ reserve: true }"
      :columnList="columnList"
      :pagination.sync="pagination"
      @selection-change="handleSelectionChange"
      @current-change="getTableList"
      @sort-change="getTableList"
      :leftToolbar="tableButtons"
    />
    <el-dialog
      :title="dialogTitle"
      :visible.sync="addStockDialog"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="cancelStockHandle"
    >
      <table-with-pagination
        :tableData="inventoryTableList"
        class="content_block"
        :columnList="inventoryTableColumn"
        :leftToolbar="tableDialogButtons"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelStockHandle">取 消</el-button>
        <el-button type="primary" @click="sureStockHandle">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="备件申领" :visible.sync="applyDialog" width="900px">
      <el-form :model="applyForm" label-width="120px" :rules="applyFormRules" ref="applyFormRef">
        <el-row>
          <el-col :span="8">
            <el-form-item label="备件名称：" prop="sparePartName">
              <div>{{ applyForm.sparePartName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备件编码：" prop="sparePartCode">
              <div>{{ applyForm.sparePartCode }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备件类型：" prop="sparePartTypeName">
              <div>{{ applyForm.sparePartTypeName }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="规格型号：" prop="spec">
              <div>{{ applyForm.spec }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库：" prop="warehouseName">
              <div>{{ applyForm.warehouseName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库库存数量：" prop="quantityTotal">
              <div>{{ applyForm.quantityTotal }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="个人仓库：" prop="personalWarehouseName">
              <div>{{ applyForm.personalWarehouseName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="个人库存数量：" prop="personalQuantity">
              <div>{{ applyForm.personalQuantity }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="申领数量：" prop="quantity">
              <el-input-number :min="0" :precision="0" v-model="applyForm.quantity"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="备注：" prop="remark">
              <el-input :maxlength="300" v-model="applyForm.remark" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelApplyHandle">取 消</el-button>
        <el-button type="primary" @click="sureApplyHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import _ from 'lodash';
import SpareTypesSelectors from '@/components/spareTypeSlelector';
import {
  getInventoryOutList,
  stockOutApi,
  getSparePartList,
  stockInApi,
  allocationInventoryApi,
  distributionApi,
  setSafeStockApi,
  applyInventoryApi,
  inStockWarehouseList,
  getWarehouseList,
  getPersonalInventory, // 个人仓信息
  getPersonalWarehouseList, // 个人仓列表
  // getInventoryDetailApi, // 详情接口
} from '@/api/stock.js';
import { searchSpareParts, getTypeList } from '@/api/spareParts';

export default {
  name: 'STOCKIN_PERSONAL',
  mixins: [TablePaginationMixin],
  components: {
    SpareTypesSelectors,
  },
  computed: {
    InitInventoryCol () {
      return [
        {
          tooltip: true,
          label: '备件名称',
          minWidth: '180px',
          prop: 'sparePartId',
          render: (h, { row }) => {
            return row.editFlag ? (
              <el-select
                vModel={row.sparePartId}
                filterable
                remote
                placeholder={'请输入备件名称'}
                remoteMethod={(val) => {
                  this.remoteMethod(val);
                }}
                onChange={this.sparePartChangeHandle.bind(this, row)}
              >
                {this.sparePartList.map((i, ind) => {
                  return (
                    <el-option key={ind} value={i.sparePartId} label={i.sparePartName}>
                      {i.sparePartName + '-' + i.spec}
                    </el-option>
                  );
                })}
              </el-select>
            ) : (
              <div>{row.sparePartName}</div>
            );
          },
        },
        {
          tooltip: true,
          label: '备件编码',
          minWidth: '180px',
          prop: 'sparePartCode',
        },
        {
          tooltip: true,
          label: '备件类型',
          minWidth: '180px',
          prop: 'sparePartTypeName',
        },
        {
          tooltip: true,
          label: '规格型号',
          minWidth: '180px',
          prop: 'spec',
        },
        {
          tooltip: true,
          label: '仓库',
          minWidth: '180px',
          prop: 'warehouseId',
          render: (h, { row }) => {
            return row.editFlag ? (
              <el-select
                vModel={row.warehouseId}
                disabled={!row.sparePartId}
                onChange={this.warehouseChangeHandle.bind(this, row)}
              >
                {this.warehouseList.map((i) => {
                  return (
                    <el-option key={i.warehouseId} value={i.warehouseId} label={i.warehouseName}>
                      {i.warehouseName}
                    </el-option>
                  );
                })}
              </el-select>
            ) : (
              <div>{row.warehouseName}</div>
            );
          },
        },
      ];
    },
  },
  data () {
    return {
      typeSearchList: [],
      warehouseSearchList: [],
      applyDialog: false,
      applyFormRules: {
        quantity: [{ required: true, message: '请输入大于0的整数', trigger: 'blur' }],
      },
      applyForm: {
        quantity: 0,
      },
      sparePartList: [],
      warehouseList: [],
      goalWarehouseList: [],
      tableDialogButtons: [
        {
          type: 'custom',
          render: () => {
            return !this.selectedLists.length ? (
              <el-button onClick={this.addNewLineHandle} type='primary'>
                  新增
              </el-button>
            ) : (
              ''
            );
          },
        },
      ],
      tableButtons: [
        {
          name: '出库',
          status: 'primary',
          size: 'small',
          permission: 'sparePart:stock:out',
          click: () => {
            this.inventoryHandle('out');
          },
        },
        {
          name: '入库',
          status: 'primary',
          size: 'small',
          permission: 'sparePart:stock:in',
          click: () => {
            this.inventoryHandle('in');
          },
        },
        {
          name: '调拨',
          status: 'primary',
          size: 'small',
          permission: 'sparePart:stock:allocate',
          click: () => {
            this.inventoryHandle('transfer');
          },
        },
        {
          name: '分配',
          status: 'primary',
          size: 'small',
          permission: 'sparePart:stock:distribution',
          click: () => {
            this.inventoryHandle('distribute');
          },
        },
        {
          name: '设置安全库存',
          status: 'primary',
          size: 'small',
          permission: 'sparePart:stock:setUpSafetyStock',
          click: () => {
            this.inventoryHandle('safety');
          },
        },
      ],
      form: {
        type: undefined,
        name: undefined,
        code: undefined,
        typeId: undefined,
        typeName: '全部',
        warehouseId: undefined,
      },
      selectedLists: [],
      customerList: [],
      columnList: [
        {
          minWidth: '60px',
          type: 'checkbox',
          label: '全选',
          fixed: 'left',
        },
        {
          tooltip: true,
          label: '备件名称',
          minWidth: '180px',
          prop: 'sparePartName',
        },
        {
          tooltip: true,
          label: '备件类型',
          minWidth: '180px',
          prop: 'sparePartTypeName',
        },
        {
          tooltip: true,
          label: '规格型号',
          minWidth: '180px',
          prop: 'spec',
        },
        {
          tooltip: true,
          label: '备件编码',
          minWidth: '180px',
          prop: 'sparePartCode',
        },
        {
          tooltip: true,
          label: '库存数量',
          minWidth: '180px',
          prop: 'quantity',
        },
        {
          tooltip: true,
          label: '安全库存',
          minWidth: '180px',
          prop: 'securityQuantity',
        },
        {
          tooltip: true,
          label: '仓库',
          minWidth: '180px',
          prop: 'warehouseName',
        },
        {
          tooltip: true,
          label: '仓库类型',
          minWidth: '180px',
          prop: 'warehouseTypeName',
        },
        {
          label: '操作',
          minWidth: '180px',
          prop: 'actions',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];
            if (!row.isPersonal && row.quantity > 0) {
              actions.push(
                <span
                  v-auth={'sparePart:stock:apply'}
                  class='cursor-pointer text-primary ml-1 '
                  onClick={this.openDealDialog.bind(this, row)}
                >
                    申领
                </span>,
              );
              return actions;
            }
          },
        },
      ],
      curRow: {},
      inventoryTableList: [],
      inventoryTableColumn: [],
      dialogTitle: '',
      addStockDialog: false,
      stockHandleType: '',
      newLineData: {
        editFlag: false, // 控制是否可修改
        sparePartName: '',
        sparePartId: '',
      },
      sparePartInStockList: [], // 仓库列表
    };
  },
  methods: {
    typeNameChange ({ id, name }) {
      this.form.typeId = id;
      this.form.typeName = name;
    },
    cancelApplyHandle () {
      this.applyDialog = false;
      this.applyForm.quantity = 0;
      this.applyForm.remark = '';
    },
    sureApplyHandle () {
      this.$refs.applyFormRef.validate(async (valid) => {
        if (valid) {
          const rs = await applyInventoryApi([{ ...this.applyForm }]);
          if (rs.heads.code === 200) {
            this.$message.success('备件申领提交成功');
            this.cancelApplyHandle();
            this.handleSearch();
          }
        }
      });
    },
    warehouseChangeHandle (row, id) {
      const curRowWarehouse = this.warehouseList.find((i) => i.warehouseId === id);
      row.warehouseName = curRowWarehouse.warehouseName;
      row.warehouseCode = curRowWarehouse.warehouseCode;
      row.quantityTotal = curRowWarehouse.quantity;
    },
    goalWhouseChangeHandle (row, id) {
      const curRowWarehouse = this.goalWarehouseList.find((i) => i.goalWarehouseId === id);
      row.goalWarehouseName = curRowWarehouse.goalWarehouseName;
      row.goalWarehouseCode = curRowWarehouse.goalWarehouseCode;
    },
    sparePartChangeHandle (row, sparePartId) {
      row.warehouseId = undefined;
      row.warehouseName = undefined;
      row.warehouseCode = undefined;
      row.quantityTotal = '';
      const curRowSpare = this.sparePartList.find((i) => i.sparePartId === sparePartId);
      row.sparePartName = curRowSpare.sparePartName;
      row.sparePartCode = curRowSpare.sparePartCode;
      row.sparePartTypeCode = curRowSpare.sparePartTypeCode;
      row.sparePartTypeName = curRowSpare.sparePartTypeName;
      row.sparePartTypeId = curRowSpare.sparePartTypeId;
      row.spec = curRowSpare.spec;
      if (this.stockHandleType !== 'in') {
        inStockWarehouseList({ sparePartId }).then((rs) => {
          this.warehouseList = rs.body.map((i) => ({ ...i }));
        });
      }
    },
    remoteMethod (sparePartName) {
      if (this.stockHandleType === 'in') {
        const name = sparePartName && sparePartName.trim();
        searchSpareParts({ name }).then((rs) => {
          this.sparePartList = rs.body?.list.map((i) => ({
            ...i,
            sparePartId: i.id,
            sparePartCode: i.code,
            sparePartName: i.name,
          }));
        });
      } else {
        getSparePartList({ sparePartName }).then((rs) => {
          this.sparePartList = rs.body;
        });
      }
    },
    addNewLineHandle () {
      this.inventoryTableColumn.forEach((i) => {
        this.newLineData[i.prop] = undefined;
      });
      switch (this.stockHandleType) {
      case 'out':
        this.newLineData.outType = '销售出库';
        break;
      case 'in':
        this.newLineData.inType = '采购入库';
        break;
      default:
        break;
      }
      this.newLineData.editFlag = true;
      this.inventoryTableList.push({ ...this.newLineData });
    },
    cancelStockHandle () {
      this.addStockDialog = false;
      this.inventoryTableList = [];
      this.sparePartList = [];
    },
    async sureStockHandle () {
      if (!this.inventoryTableList.length) return;
      const params = [...this.inventoryTableList];
      for (let i = 0; i < params.length; i++) {
        let curLine = params[i];
        switch (this.stockHandleType) {
        case 'out':
          if (curLine.sparePartId === undefined) {
            return this.$message.error('请选择备件');
          }
          if (curLine.warehouseId === undefined) {
            return this.$message.error('请选择仓库');
          }
          if (
            curLine.quantity === undefined
                || +curLine.quantity > curLine.quantityTotal
                || +curLine.quantity === 0
          ) {
            return this.$message.error('数量请输入小于库存数量的正整数');
          }
          break;
        case 'in':
          if (curLine.sparePartId === undefined) {
            return this.$message.error('请选择备件');
          }
          if (curLine.warehouseId === undefined) {
            return this.$message.error('请选择仓库');
          }
          if (curLine.quantity === undefined || +curLine.quantity === 0) {
            return this.$message.error('数量请输入正整数');
          }
          break;
        case 'transfer':
        case 'distribute':
          if (curLine.sparePartId === undefined) {
            return this.$message.error('请选择备件');
          }
          if (curLine.warehouseId === undefined || curLine.goalWarehouseId === undefined) {
            return this.$message.error('请选择仓库');
          }
          if (
            curLine.quantity === undefined
                || +curLine.quantity > curLine.quantityTotal
                || +curLine.quantity === 0
          ) {
            return this.$message.error('数量请输入小于库存数量的正整数');
          }
          break;
        case 'safety':
          if (curLine.sparePartId === undefined) {
            return this.$message.error('请选择备件');
          }
          if (curLine.warehouseId === undefined) {
            return this.$message.error('请选择仓库');
          }
          break;
        default:
          break;
        }
      }
      let res;
      let message;
      switch (this.stockHandleType) {
      case 'out':
        res = await stockOutApi(params);
        message = '备件出库成功';
        break;
      case 'in':
        res = await stockInApi(params);
        message = '备件入库成功';
        break;
      case 'transfer':
        res = await allocationInventoryApi(params);
        message = '备件调拨成功';
        break;
      case 'distribute':
        res = await distributionApi(params);
        message = '备件分配成功';
        break;
      case 'safety':
        res = await setSafeStockApi(params);
        message = '设置安全库存成功';
        break;
      default:
        break;
      }
      if (res.heads.code === 200) {
        this.$message.success(message);
        this.cancelStockHandle();
        this.handleSearch();
      }
    },
    inventoryHandle (type) {
      this.inventoryTableColumn = [...this.InitInventoryCol];
      this.inventoryTableList = _.cloneDeep(this.selectedLists);
      this.stockHandleType = type;
      switch (type) {
      case 'out':
        this.dialogTitle = '备件出库';
        this.inventoryTableList.forEach((i) => {
          i.outType = '销售出库';
          if (i.quantity !== undefined) {
            i.quantityTotal = i.quantity;
            i.quantity = '';
          }
        });
        this.inventoryTableColumn.push(
          {
            tooltip: true,
            label: '出库类型',
            minWidth: '180px',
            prop: 'outType',
          },
          {
            tooltip: true,
            label: '库存数量',
            minWidth: '180px',
            prop: 'quantityTotal',
          },
          {
            tooltip: true,
            label: '出库数量',
            minWidth: '180px',
            prop: 'quantity',
            render: (h, { row }) => {
              return h('el-input', {
                props: {
                  size: 'small',
                  value: row.quantity,
                },
                attrs: {
                  maxlength: 20,
                  size: 'mini',
                },
                on: {
                  input: (val) => {
                    const newVal = !isNaN(parseInt(val)) && +val !== 0 ? parseInt(val) : undefined;
                    row.quantity = newVal;
                  },
                },
              });
            },
          },
        );
        break;
      case 'in':
        this.getWarehouseListHandle();
        this.inventoryTableList.forEach((i) => {
          i.inType = '采购入库';
          i.quantity = '';
        });
        this.inventoryTableColumn.push(
          {
            tooltip: true,
            label: '入库类型',
            minWidth: '180px',
            prop: 'inType',
          },
          {
            tooltip: true,
            label: '入库数量',
            minWidth: '180px',
            prop: 'quantity',
            render: (h, { row }) => {
              return h('el-input', {
                props: {
                  size: 'small',
                  value: row.quantity,
                },
                attrs: {
                  maxlength: 20,
                  size: 'mini',
                },
                on: {
                  input: (val) => {
                    const newVal = !isNaN(parseInt(val)) && +val !== 0 ? parseInt(val) : undefined;
                    row.quantity = newVal;
                  },
                },
              });
            },
          },
        );
        this.dialogTitle = '备件入库';
        break;
      case 'transfer':
        this.getWarehouseListHandle();
        this.inventoryTableList.forEach((i) => {
          if (i.quantity !== undefined) {
            i.quantityTotal = i.quantity;
            i.quantity = '';
          }
        });
        this.inventoryTableColumn.push(
          {
            tooltip: true,
            label: '库存数量',
            minWidth: '180px',
            prop: 'quantityTotal',
          },
          {
            tooltip: true,
            label: '目标仓库',
            minWidth: '180px',
            prop: 'goalWarehouseId',
            render: (h, { row }) => {
              return (
                <el-select vModel={row.goalWarehouseId} onChange={this.goalWhouseChangeHandle.bind(this, row)}>
                  {this.goalWarehouseList.map((i) => {
                    return (
                      <el-option key={i.goalWarehouseId} value={i.goalWarehouseId} label={i.goalWarehouseName}>
                        {i.goalWarehouseName}
                      </el-option>
                    );
                  })}
                </el-select>
              );
            },
          },
          {
            tooltip: true,
            label: '调拨数量',
            minWidth: '180px',
            prop: 'quantity',
            render: (h, { row }) => {
              return h('el-input', {
                props: {
                  size: 'small',
                  value: row.quantity,
                },
                attrs: {
                  maxlength: 20,
                  size: 'mini',
                },
                on: {
                  input: (val) => {
                    const newVal = !isNaN(parseInt(val)) && +val !== 0 ? parseInt(val) : undefined;
                    row.quantity = newVal;
                  },
                },
              });
            },
          },
        );
        this.dialogTitle = '备件调拨';
        break;
      case 'distribute':
        this.getPersonalWarehouseListHandle();
        this.inventoryTableList.forEach((i) => {
          if (i.quantity !== undefined) {
            i.quantityTotal = i.quantity;
            i.quantity = '';
          }
        });
        this.inventoryTableColumn.push(
          {
            tooltip: true,
            label: '库存数量',
            minWidth: '180px',
            prop: 'quantityTotal',
          },
          {
            tooltip: true,
            label: '目标仓库',
            minWidth: '180px',
            prop: 'goalWarehouseId',
            render: (h, { row }) => {
              return (
                <el-select vModel={row.goalWarehouseId} onChange={this.goalWhouseChangeHandle.bind(this, row)}>
                  {this.goalWarehouseList.map((i) => {
                    return (
                      <el-option key={i.goalWarehouseId} value={i.goalWarehouseId} label={i.goalWarehouseName}>
                        {i.goalWarehouseName}
                      </el-option>
                    );
                  })}
                </el-select>
              );
            },
          },
          {
            tooltip: true,
            label: '分配数量',
            minWidth: '180px',
            prop: 'quantity',
            render: (h, { row }) => {
              return h('el-input', {
                props: {
                  size: 'small',
                  value: row.quantity,
                },
                attrs: {
                  maxlength: 20,
                  size: 'mini',
                },
                on: {
                  input: (val) => {
                    const newVal = !isNaN(parseInt(val)) && +val !== 0 ? parseInt(val) : undefined;
                    row.quantity = newVal;
                  },
                },
              });
            },
          },
        );
        this.dialogTitle = '备件分配';
        break;
      case 'safety':
        this.inventoryTableList.forEach((i) => {
          i.quantity = '';
        });
        this.inventoryTableColumn.push({
          tooltip: true,
          label: '安全库存数量',
          minWidth: '180px',
          prop: 'quantity',
          render: (h, { row }) => {
            return h('el-input', {
              props: {
                size: 'small',
                value: row.quantity,
              },
              attrs: {
                maxlength: 20,
                size: 'mini',
              },
              on: {
                input: (val) => {
                  const newVal = !isNaN(parseInt(val)) ? parseInt(val) : '';
                  row.quantity = newVal;
                },
              },
            });
          },
        });
        this.dialogTitle = '设置安全库存';
        break;
      default:
        break;
      }
      if (!this.selectedLists.length) {
        this.inventoryTableColumn.push({
          label: '操作',
          minWidth: '180px',
          prop: 'actions',
          fixed: 'right',
          render: (h, { index }) => {
            let actions = [];
            actions.push(
              <span class='cursor-pointer text-primary ml-1 ' onClick={this.delCurRow.bind(this, index)}>
                  删除
              </span>,
            );
            return actions;
          },
        });
      }

      this.addStockDialog = true;
    },
    delCurRow (index) {
      this.$confirm('确认删除此条信息？', '删除').then(() => {
        this.inventoryTableList.splice(index, 1);
      });
    },
    handleSearch () {
      this.clearCheckBoxData();
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      Object.keys(this.form).forEach((i) => {
        this.form[i] = undefined;
      });
      this.form.typeName = '全部';
      this.handleSearch();
    },
    async getTableList () {
      const param = {
        ...this.form,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };
      delete param.typeName;
      const rs = await getInventoryOutList(param);
      if (rs.heads.code === 200) {
        const { list, total } = rs.body;
        this.customerList = list;
        this.pagination.total = total;
      }
    },
    openDealDialog (row) {
      this.applyForm = _.cloneDeep(row);

      getPersonalInventory({ sparePartId: row.sparePartId }).then((rs) => {
        const { quantity, warehouseCode, warehouseId, warehouseName } = rs.body;
        this.applyForm = {
          ...this.applyForm,
          personalWarehouseCode: warehouseCode,
          personalWarehouseId: warehouseId,
          personalWarehouseName: warehouseName,
          personalQuantity: quantity,
        };
        this.applyForm.quantityTotal = this.applyForm.quantity;
        this.applyForm.quantity = 0;
        this.applyDialog = true;
      });
    },
    // 获取当前选中项
    handleSelectionChange (selectedLists) {
      this.selectedLists = [...selectedLists];
    },
    clearCheckBoxData () {
      this.$refs.stockTableRef && this.$refs.stockTableRef.$refs.vxeTable.clearCheckboxRow();
      this.$refs.stockTableRef && this.$refs.stockTableRef.$refs.vxeTable.clearCheckboxReserve();
      this.selectedLists = [];
    },
    async getWarehouseListHandle () {
      const rs = await getWarehouseList();
      if (rs.body?.list) {
        const { list } = rs.body;
        if (this.stockHandleType === 'in') {
          this.warehouseList = list.map((i) => ({
            ...i,
            warehouseName: i.name,
            warehouseId: i.id,
            warehouseCode: i.code,
          }));
        } else if (this.stockHandleType === 'transfer' || this.stockHandleType === 'distribute') {
          this.goalWarehouseList = list.map((i) => ({
            ...i,
            goalWarehouseName: i.name,
            goalWarehouseCode: i.code,
            goalWarehouseId: i.id,
          }));
        }
      } else {
        this.warehouseList = [];
        this.goalWarehouseList = [];
      }
    },
    async getPersonalWarehouseListHandle () {
      const rs = await getPersonalWarehouseList();
      this.goalWarehouseList = rs.body.map((i) => ({
        ...i,
        goalWarehouseName: i.warehouseName,
        goalWarehouseCode: i.warehouseCode,
        goalWarehouseId: i.warehouseId,
      }));
    },
  },
  created () {
    Promise.all([getTypeList(), getWarehouseList()]).then((rsList) => {
      this.typeSearchList = rsList[0]?.body?.list;
      this.warehouseSearchList = rsList[1]?.body?.list;
    });
  },
};
</script>
<style scoped lang="scss">
  .el-icon-setting {
    font-size: 28px;
    margin-left: 8px;
    border: 1px #dcdff6 solid;
    border-radius: 4px;
    color: #dcdff6;
    height: 32px;
    line-height: 32px;
    cursor: pointer;

    &:hover {
      background-color: #409eff;
      border-color: #409eff;
    }
  }
</style>
